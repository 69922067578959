@layer components {

    .form {
        @apply mt-8 mx-4 w-full lg:w-2/4
    }

    .deleteForm {
        @apply mt-4 w-full justify-center
    }

    .form-label {
        @apply leading-7 text-sm
    }

    .form-error {
        @apply mb-2 text-xs text-red-500
    }

    .form-input {
        @apply mb-4 w-full p-4  border border-gray-400 text-sm rounded-lg
    }

    .form-input:focus {
        @apply ring-blue-500
    }

    .form-textarea {
        @apply w-full mb-4 p-4 text-sm bg-gray-50 rounded-lg border border-gray-400
    }

    .form-textarea:focus {
        @apply ring-blue-500
    }

    .form-button {
        @apply text-white bg-blue-600 border-0 py-2 px-8 mx-auto rounded-full
    }

    .form-button-disabled {
        @apply text-white bg-blue-700 border-0 py-2 px-8 mx-auto rounded-full
    }

    .form-button:hover {
        @apply bg-blue-700
    }

    .form-button:focus {
        @apply outline-none
    }

    .form-delete-button {
        @apply text-white bg-red-700 border-0 py-2 px-8 mx-auto rounded-full
    }

    .form-delete-button:hover {
        @apply bg-red-800
    }

    .form-delete-button:focus {
        @apply outline-none
    }
}
