@use "variables";
@use "components/table";
@use "components/paginate";
@use "components/notification";
@use "components/home";
@use "components/form";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, sans-serif;
}
